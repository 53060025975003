/* Подключение шрифтов */

@font-face {
	font-family: 'Futura PT';
	font-weight: 100;
	src: url('fonts/futura-pt-light.ttf');
}

@font-face {
	font-family: 'Futura PT';
	font-weight: 200;
	src: url('fonts/futura-pt-book.ttf');
}

@font-face {
	font-family: 'Futura PT';
	font-weight: 300;
	src: url('fonts/futura-pt-medium.ttf');
}

@font-face {
	font-family: 'Futura PT';
	font-weight: 400;
	src: url('fonts/futura-pt-demi.ttf');
}

@font-face {
	font-family: 'Futura PT';
	font-weight: 500;
	src: url('fonts/futura-pt-heavy.ttf');
}

@font-face {
	font-family: 'Futura PT';
	font-weight: 600;
	src: url('fonts/futura-pt-bold.ttf');
}

@font-face {
	font-family: 'Futura PT';
	font-weight: 700;
	src: url('fonts/futura-pt-extra-bold.ttf');
}

/* Общие параметры */

/*body {
	font-size: 100%;
	width: 100%;
	box-sizing: border-box;
}*/

a, p, span, h1, h2, h3, h4, h5, h6, input, th, td, label, li, textarea {
	font-family: 'Futura PT';
	font-weight: 200;
	color: black;
	text-decoration: none;
    margin: 0;
	/*box-sizing: border-box;*/
}

input:focus, textarea:focus {
    outline: none;
}

ul {
    list-style:none;
    padding:0;
    margin:0;
}

/*div {
	box-sizing: border-box;
}*/

/*** Элементы ***/

/*@media (min-width: 1024px) and (orientation: landscape) {
    .main { display: none; }
    .pc { display: flex!important; }
}

.pc {
    display: none;
    width: 100vw;
    height: 100vh;
}

.pc svg {
    margin-top: 54px;
    margin-bottom: 16px;
}

.pc-1, .pc-2 {
    width: 50%;
    padding: 0 16px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.pc-1 h1 {
    margin-bottom: 12px;
}

.pc-qr-undersign {
    font-size: 24px;
    font-weight: 500;
}

.pc-header {
    font-size: 36px;
    font-weight: 600;
    position: fixed;
    text-align: center;
    top: 16px;
    left: 0;
    right: 0;
}

.pc-footer, .pc-footer p {
    position: fixed;
    text-align: center;
    bottom: 8px;
    left: 0;
    right: 0;
}

.pc .inner {
    margin: 0 auto;
}

.pc-1 {
    text-align: center;
}

.pc h1.left {
    text-align: left !important;
}*/

.law {
    font-size: 12px !important;
    text-align: center;
    color: #777;
}

.law.small {
    font-size: 10px !important;
}

.content {
    width: 100vw;
    /*height: 100vh;*/
    /*min-height: -webkit-fill-available;*/
}

/* TEMP FOR DEBUG PURPOSES */
/*.content {
    background: #fdd;
    overflow-y: scroll;
}*/
/* TEMP FOR DEBUG PURPOSES */

@media (min-width: 1024px) {
    .mobile-only { display: none; }
    .half-or-full {
        width: 50% !important;
        display: inline-block;
    }
    .shop-one-item .first {
        padding: 0 calc(132px - 3.125vw) !important;
    }
    .hof-container {
        overflow-y: scroll;
        position: relative;
    }
    .half-or-full.first {
        padding: 16px;
    }
    .cart .half-or-full.last {
        padding: 16px;
    }
}

@media (max-width: 1023px) {
    .desktop-only { display: none; }
    .shop-one-item .half-or-full.last { margin-bottom: 256px; }
    .one-stone .half-or-full.last { margin-bottom: 80px; }
}

.content-cropped {
    width: 100%;
    min-height: calc(100dvh - 128px); /* 64px + 64px */
    margin-top: 64px;
    margin-bottom: 64px;
}

@media (min-width: 1024px) {
    .content-top-cropped {
        max-height: calc(100dvh - 64px);
        overflow: hidden;
        display: flex;
    }
}

.content-top-cropped {
    width: 100%;
    min-height: calc(100dvh - 64px);
    margin-top: 64px;
}

@media (min-width: 640px) {
    .popup {
        width: 400px!important;
    }
}

/* Попапы */
.popup {
    position:fixed;
    right: 12px;
    background: white;
    border-radius: 60px;
    z-index: 9999;
    max-height: calc(100dvh - 24px);
    width: calc(100% - 24px);
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.2);
    padding: 28px 32px;
    box-sizing: border-box;
    overflow-y: scroll;
    overscroll-behavior: contain;
}

.popup.top {
    top: 12px;
}

@media (max-width: 1023px) {
    .popup.bottom {
        bottom: 12px;
    }
}

@media (min-width: 1024px) {
    .popup.left {
        left: 12px !important;
    }
}

.popup-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100dvh;
}

.popup-background.blur {
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    opacity: 1.0;
    z-index: 9998;
}

.popup-background.color {
    background-color: black;
    opacity: 0.1;
    z-index: 9997;
}

.popup, .popup-background {
    transition: all 0.2s ease-out;
}

.popup-animation-appear > .popup, .popup-animation-exit-done > .popup {
    opacity: 0;
}

.popup-animation-exit > .popup, .popup-animation-appear-done .popup {
}

.popup-animation-appear > .popup-background, .popup-animation-exit-done > .popup-background {
    opacity: 0;
}

.popup-animation-exit > .popup-background, .popup-animation-appear-done > .popup-background {
}

.popup h1, .popup .close-button, .pc h1 {
    text-align: center;
    font-size: 24px;
    font-weight: 600;
}

.popup .close-button {
    margin-top: 24px;
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
    cursor: pointer;
}

.popup h2, .pc h2 {
    font-size: 20px;
    font-weight: 500;
}

.popup h3, .pc h3 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 6px;
}

.pc h4 {
    font-size: 20px;
    font-weight: 200;
    text-align: center;
}

.popup h3.no-margin {
    margin-bottom: 0;
}

.popup p, .pc-2 p {
    font-size: 16px;
    font-weight: 200;
}

/* Верхнее меню */
.nav-bar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 64px;
    z-index: 9000;
}

.nav-bar.color {
    background-color: white;
}

.nav-bar.transparent {
}

.nav-title {
    text-align: center;
    font-weight: 600;
    font-size: 32px;
    line-height: 32px;
    margin-top: 16px;
}

@media(min-width:1024px) {
    .nav-title {
        text-align: left;
        padding-left: 24px;
        margin-top: 14px;
    }
}

@media(max-width: 300px) {
    .nav-title {
        display: none;
    }
}

.nav-title.color {
    color: black;
}

.nav-title.transparent {
    color: white;
}

.nav-button {
    width: 24px;
    height: 24px;
    position: absolute;
    right: 16px;
    top: 24px;
    margin: 0;
    padding: 0;
}

.nav-button > img {
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.nav-button.transparent > img {
    filter: invert(100%);
}

.nav-bubble {
    text-align: center;
}

.nav-bubble > ul {
    text-align: center;
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.nav-bubble > ul > li {
    padding: 10px 0;
    cursor: pointer;
    font-weight: 600 !important;
    font-size: 24px;
}

.nav-bubble > ul > li:first-child {
    padding-top: 0;
}

.nav-bubblt > ul > li:last-child {
    padding-bottom: 0;
}

/* Нижнее меню */
.menu-bar {
    width: 100vw;
    height: 64px;
    position: fixed;
    left: 0;
    bottom: 0;
    display: flex;
}

.menu-bar.color {
    background: white;
}

.menu-bar.transparent {
}
.menu-button-wrapper-edge {
    width: 33vw;
    display: inline;
    text-align: center;
    margin-top: 10px;
}

.menu-button-wrapper-center {
    width: 34vw;
    display: inline;
    text-align: center;
    margin-top: 10px;
}

.menu-button {
}

.menu-button-icon {
    width: 28px;
    height: 28px;
    opacity: 0.4;
}

.menu-button-text {
    font-weight: 300;
    font-size: 12px;
    opacity: 0.5;
}

.menu-button-text.transparent {
    color: white;
}

.menu-button-text.color {
    color: black;
}

.menu-button-icon.selected {
    opacity: 1.0;
}

.menu-button-text.selected {
    opacity: 1.0;
}

.menu-button-icon.transparent {
    filter: invert(100%);
}

/** Магазин **/

/* Каталог товаров */

.shop {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;
    align-content: flex-start;
    padding: 0 5px;
    box-sizing: border-box;
    position: relative;
}

@media (max-width: 1023px) {
    .shop {
        /*justify-content: space-evenly;*/
    }
}

.shop-item {
    width: calc(50vw - 6px); /* 10px + 16px TODO разобраться */
    min-width: 120px;
    max-width: 300px;
    text-align: center;
    padding: 8px;
    margin: 18px 0;
    position: relative;
    transition: all 0.2s ease-out;
    cursor: pointer;
}

.shop-item.fade {
    opacity: 0;
    transition: all 0.2s ease-out;
}

.shop-item-photo {
    width: 100%;
    height: auto;
    margin-bottom: 8px;
    border-radius: 10%; /* was 24px */
    /*box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);*/
}

.shop-item-title {
    font-size: 16px;
    font-weight: 400;
}

.shop-item-subtitle {
    font-size: 14px;
    font-weight: 200;
    margin-bottom: 32px; /* 14px price + 18px */
}

.shop-item-price {
    font-size: 14px;
    font-weight: 200;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.small-like {
    width: 24px;
    height: 24px;
    border-radius: 24px;
    /*background-color: white;*/
    background-image: url('icons/icon-like.png');
    background-size: 60%;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0.3;
    position: absolute;
    right: 16px;
    top: 16px;
    /*box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);*/
    transition: all 0.2s ease-out;
}

.small-like.pressed {
    background-image: url('icons/icon-like-pressed.png') !important;
    opacity: 1;
}

.shop-cart-button {
    position: fixed;
    bottom: 16px; /* was 80px = 64 + 16 px */
    background: white;
    border-radius: 999px;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 32px;
    cursor: pointer;
}

.shop-cart-button > img {
    width: auto;
    height: 32px;
}

.shop-cart-button > p {
    font-size: 16px;
    font-weight: 400;
    color: black;
    margin-left: 12px;
    padding: 16px 0;
}

/* Страница одного товара */

.shop-one-item {
    text-align: center;
    /*padding-bottom: 80px;*/
    box-sizing: border-box;
    justify-content: space-between;
}

.shop-one-item-main-photo {
    /*margin: 16px auto;*/
    width: calc(100% - 32px);
    /*max-width: calc(100vh - 240px);*/
    margin-bottom: 32px; /*was 32 then 64px*/
    /*box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);*/
}

@media (min-width: 1024px) {
    .shop-one-item-main-photo {
        margin-top: 32px;
        border-radius: 10%; /* was 48px */
    }
    .shop-one-item-bottom-placeholder {
        width: 100%;
        height: 128px;
    }
}

@media (max-width: 1023px) {
    .shop-one-item-main-photo {
        margin-top: 8px;
        width: 100%;
    }
    .shop-one-item-bottom-placeholder {
        display: none;
    }
}

.shop-one-item-additional-photo {
    width: calc(100% - 32px);
    margin: 16px auto;
    display: block;
    /*box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);*/
    /*border-radius: 30px;*/
}

.shop-one-item .index-video-wrapper {
    height: 80vh;
    width: 100%;
    margin-top: 32px;
}

.shop-one-item video {
    height: 80vh;
    width: 100%;
    object-fit: cover;
}

.popup video {
    height: 100vw;
    max-height: 80vh;
    width: 100%;
    object-fit: cover;
    margin: 5vw 0;
    border-radius: 36px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.shop-one-item-header {
    text-align: left!important;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: calc(100% - 32px);
    margin: 0 auto;
    margin-top: 12px;
    margin-bottom: 4px;
}

.shop-one-item-title-and-subtitle {
}

.shop-one-item-title {
    font-weight: 500;
    font-size: 24px;
    /*margin-top: 48px;*/
}

.shop-one-item-subtitle {
    font-size: 16px;
}

.shop-one-item-stones-title {
    margin-top: 36px;
    margin-bottom: 24px;
    font-size: 20px;
    font-weight: 500;
}

.shop-one-item-price {
    font-size: 16px;
    /*margin-top: 12px;
    margin-bottom: 64px;*/
}

.shop-one-item-description-title {
    font-size: 22px;
    font-weight: 500;
    text-align: left;
    margin-left: 16px;
    margin-right: 16px;
    margin-bottom: 12px;
}

.shop-one-item-description-text {
    font-size: 18px;
    font-weight: 200;
    text-align: left;
    margin-left: 16px;
    margin-right: 16px;
    margin-bottom: 36px;
}

.size-hint {
    margin-top: -14px;
    font-size: 17px;
    font-weight: 400;
}

@media (min-width: 1024px) {
    /*.shop-one-item-mid-buttons {
        display: flex;
    }*/
    /*.shop-one-item-bottom-menu {
        position: absolute!important;
    }*/
}

@media (max-width: 1023px) {
    /*.shop-one-item-mid-buttons {
        position: fixed;
        bottom: 0;
        left: 0;
    }*/
    .shop-one-item-bottom-half:not(.noshadow) {
        box-shadow: 0px -5px 5px rgba(0, 0, 0, 0.05);
    }
}

:not(.thanks) > .shop-one-item-bottom-half.noshadow .shop-one-item-bottom-menu,
.flex-center {
    justify-content: center !important;
}

@media (max-width: 1023px) {
    .shop-one-item-bottom-half.noshadow .shop-one-item-bottom-menu {
        justify-content: center !important;
    }
}

.shop-one-item-top-half {
    height: calc(100vh - 64px - 128px);
    overflow-y: scroll;
}

.shop-one-item-bottom-half {
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    /*justify-content: space-between;*/
    background-color: white;
    width: 100%;
    z-index: 9;
}

:not(.thanks) > .shop-one-item-bottom-half:not(.cancel-payment-button) {
    min-height: 128px;
}

.shop-one-item-bottom-menu {
    width: 100%;
    padding: 0 16px;
    overflow: auto;
    background-color: white;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.shop-one-item-back {
    /*position: fixed;
    bottom: 24px;
    left: 24px;*/
    width: 32px;
    height: 32px;
    cursor: pointer;
}

@media (min-width: 1024px) {
    /*.shop-one-item-back {
        width: 24px;
        height: 24px;
        left: 12px;
        bottom: 28px;
    }*/
}

@media (max-width: 1023px) {
    /*.shop-one-item-mid-buttons {
        display: inline-block;
    }*/
}

.shop-one-item-button {
    text-align: center;
    border-radius: 999px;
    margin: 16px;
    cursor: pointer;
}

.shop-one-item-button.black {
    background-color: black;
}

.disabled {
    background-color: #ccc !important;
    cursor: not-allowed;
}

.shop-one-item-button.white {
    background-color: white;
    border: 1px black solid;
}

.shop-one-item-button > p {
    font-weight: 400;
    font-size: 18px;
    padding: 11px 32px;
}

.shop-one-item-button.black > p, .shop-one-item-button.black span {
    color: white;
}

.shop-one-item-button.white > p, .shop-one-item-button.white span {
    color: black;
}

.shop-one-item-button .hint {
    font-weight: 200 !important;
    margin-left: 4px;
    font-size: 16px;
}

.shop-one-item-add-to-cart {
}

.shop-one-item-like {
    /*position: absolute;
    bottom: 24px;
    right: 24px;*/
    width: 32px;
    height: 32px;
    transition: all 0.2s ease-out;
    cursor: pointer;
}

.shop-one-item-choose-size {
    font-size: 24px;
    font-weight: 500;
}

/* Корзина и оформление заказа */

.cart {
    width: 100%;
    padding: 0 32px;
    padding-bottom: 80px;
    box-sizing: border-box;
}

.cart-list {
    width: 100%;
}

.cart-list-item {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 12px 0;
}

.cart-list-photo {
    width: 96px;
    height: auto;
    border-radius: 18%; /* was 16px */
    /*box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);*/
}

.cart-list-text {
    width: calc(100% - 96px - 32px - 18px);
    margin-left: 18px;
    -webkit-mask: -webkit-linear-gradient(to left, white, 10%, rgba(0, 0, 0, 0));
}

.cart-list-title {
    font-size: 16px;
    font-weight: 500;
}

.cart-list-subtitle {
    font-size: 14px;
    font-weight: 200;
}

.cart-list-size {
    font-size: 14px;
    font-weight: 200;
    margin-top: 2px;
}

.cart-list-price {
    font-size: 14px;
    font-weight: 200;
    margin-top: 8px;
}

.cart-list-remove {
    width: 32px;
    height: auto;
}

.cart-bonus-row {
    width: 100%;
    margin-top: 28px;
    display: flex;
    align-items: center;
    justify-content: space-between; /* kostyl'? dunno how works */
}

.cart-bonus-text {
    font-size: 20px;
    font-weight: 500;
    text-decoration: underline;
    width: calc(100% - 64px);
}

.cart-bonus-text.added {
    width: calc(100% - 64px - 32px)!important;
    text-decoration: none;
}

.cart-bonus-remove {
    width: 32px;
    height: auto;
}

.centered-wrapper {
    width: 100%;
    text-align: center;
}

.bonus-popup-input {
    font-size: 36px;
    font-weight: 600;
    border: none;
    border-radius: 0;
    border-bottom: 3px black solid;
    width: calc(100% - 80px);
    max-width: 216px;
    text-align: center;
    margin-top: 24px;
    text-transform: uppercase;
}

.bonus-popup-select {
    width: 50%;
    text-align: center;
    display: inline-block;
}

.bonus-popup-select-sum {
    line-height: 24px;
    margin-top: 24px;
    font-size: 24px!important;
    font-weight: 600!important;
}

.bonus-popup-select-hint {
    font-size: 16px;
    font-weight: 200;
}

.bonus-popup-select-left {
}

.bonus-popup-select-right {
}

/* Оформление заказа */

*, *:before, *:after {
  box-sizing: inherit;
}

.make-order-title {
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    margin-top: 64px;
    margin-bottom: 8px;
}

@media(min-width: 1024px) {
    .centered-wrapper {
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;
    }
}

.make-order-input-title {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 16px;
    margin-top: 24px;
}

.make-order-input {
    font-size: 16px;
    text-align: center;
    border: none;
    border-radius: 0;
    border-bottom: 2px black solid;
    width: 100%;
    margin-bottom: 32px;
}

.make-order-phone-hint {
    font-size: 12px;
    margin-top: -24px;
    margin-bottom: 24px;
}

.make-order-hint {
    font-size: 12px;
    text-align: center;
}

.make-order-radio {
    font-size: 16px;

    display: flex;
    align-items: center;
    margin: 8px 0;
    margin-left: 4px;
}

.make-order-radio-hint {
    margin-left: 4px;
    font-weight: 500;
}

.make-order-radio input {
    -webkit-appearance: none;
    appearance: none;
    background-color: white;
    margin: 0;
    margin-right: 5px;
    font-size: 16px;

    color: white;
    width: 1.15em;
    height: 1.15em;
    border: 0.15em solid black;
    border-radius: 50%;
    position: relative;
}

.make-order-radio input:checked::before {
    content: "";
    width: 0.6em;
    height: 0.6em;
    border-radius: 50%;
    background-color: black;
    position: absolute;
    left: 0.125em;
    top: 0.125em;
}

.make-order-add-comment {
    font-size: 16px;
    font-weight: 500;
    text-decoration: underline;
    margin: 24px 0;
}

.make-order-final-button {
    text-align: center;
    border-radius: 999px;
    margin: 16px;
    margin-bottom: 8px;
    background-color: black;
    color: white;
    font-weight: 400;
    font-size: 18px;
    padding: 11px 32px;
}

.make-order-area {
    background-color: white;
    border: 1.5px black solid;
    border-radius: 30px;
    resize: none;
    width: 100%;
    padding: 18px 24px;
    box-sizing: border-box;
    font-size: 16px;
}

.make-order-privacy {
    font-size: 12px;
    text-decoration: underline;
    text-align: center;
}

.cart-bottom {
    height: 80px;
    width: 100%;
    background-color: white;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1;
}

.cart-bottom-back {
    position: fixed;
    bottom: 24px;
    left: 24px;
    width: 32px;
    height: 32px;
    z-index: 2; /* not working without this because is fixed */
    cursor: pointer;
}

.cart-bottom-total {
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    position: absolute;
    bottom: 28px;
    left: 0;
    right: 0;
}

#cdek-map {
    width: 100%;
    height: 100vw;
    z-index: 0;
    margin-top: 16px;
}

#cdek-map div {
    z-index: 0;
}

.thanks {
    padding: 80px 32px;
    box-sizing: border-box;
    display: block!important;
}

@media(min-width:1024px) {
    .thanks {
        max-width: 512px;
    }
}

.thanks-title {
    font-size: 24px;
    font-weight: 600;
    margin-top: 20vh;
    margin-bottom: 16px;
}

.thanks-number-hint {
    font-size: 18px;
}

.thanks-subtitle {
    font-size: 18px;
    font-weight: 400;
}

.thanks-number {
    font-size: 20px;
    font-weight: 500;
}

.thanks-hint {
    margin-top: 16px;
    font-size: 16px;
}

/** Каталог камней **/

.stones {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-content: flex-start;
    align-items: baseline;
    padding-left: 5px;
    padding-right: 5px;
    box-sizing: border-box;
    position: relative;
}

.stones > div {
    cursor: pointer;
}

@media (min-width: 1024px) {
    .stones {
        margin-bottom: 32px;
    }
}

.stones-item {
    width: calc(50vw - 21px); /* 10px + 16px TODO разобраться */
    min-width: 120px;
    max-width: 300px;
    height: auto;
    text-align: center;
    padding: 0;
    margin: 8px;
    position: relative;
    border-radius: 20px;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.one-stone {
    text-align: center;
    /*padding-bottom: 96px;*/
}

.one-stone .first {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding-bottom: 80px;
}

.one-stone-title {
    font-weight: 500;
    font-size: 24px;
    margin-top: 24px;
    padding-top: 24px;
}

.one-stone-subtitle {
    font-weight: 500;
    font-size: 24px;
    margin-bottom: 8px;
    margin-top: 24px;
}

.one-stone-card {
    margin: 16px;
    box-sizing: border-box;
    border-radius: 30px;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.2);
    width: 320px;
}

.stones object {
    pointer-events: none;
}

.one-stone-bottom-menu {
    height: 80px;
    width: 100%;
    position: fixed;
    background-color: white;
    bottom: 0;
    left: 0;
    z-index: 1;
    display: flex;
    text-align: center;
    justify-content: center;
}

/* Главный экран */

.index {
    background-color: black;
}

.index video {
    height: 100dvh;
    width: 100vw;
    object-fit: cover;
    display: block;
}

.index-video-wrapper {
    height: 100dvh;
    width: 100vw;
    object-fit: cover;
    display: block;
    background-color: black;
    position: relative;
}

.index-video-title {
    font-size: 22px;
    font-weight: 400;
    position: absolute;
    left: 24px;
    bottom: 120px;
    color: white;
}

.index-video-subtitle {
    font-size: 18px;
    font-weight: 200;
    position: absolute;
    left: 24px;
    bottom: 96px;
    color: white;
}

.index-button-wrapper {
    position: fixed;
    right: 16px;
    bottom: 80px;
}

.index-button {
    width: 32px;
    height: auto;
    display: block;
    filter: invert(100%);
    margin: 32px 0;
}

.index-button:first-child {
    margin-bottom: -8px;
}

.index-button.no-inversion {
    filter: none;
}

.index-share {
    text-align: center!important;
}

.index-share-title {
    font-size: 18px!important;
    font-weight: 400!important;
    margin-bottom: 16px;
}

.index-share-qr {
    margin: 8px auto;
}

.index-share-link {
    font-size: 16px!important;
}

.index-share-button {
    text-align: center;
    border-radius: 999px;
    margin: 16px;
    margin-bottom: 8px;
    background-color: black;
    color: white;
    font-weight: 400!important;
    font-size: 18px!important;
    padding: 11px 32px;
}

.index-share-button:hover {
    background-color: #777;
}

.red {
    background-color: red;
}

.red > p {
    color: white;
}

.red-text {
    color: orange;
}

#payment-form {
    width: 100%;
    text-align: center;
}

@media (max-width: 212px){ /* was 420 */
    .filter-list {
        justify-content: start!important;
    }
}

.filter-list {
    /*position: fixed;
    top: 80px;
    z-index: 9999;*/ /* for future */
    padding: 8px;
    text-align: center;
    width: 100%;
    overflow-x: scroll;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
}

.filter-list.size-list {
    justify-content: flex-start;
}

.filter {
    box-sizing: border-box;
    border: 1px black solid;
    border-radius: 14px;
    margin: 0 4px;
    padding: 2px 12px;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    color: black;
    background-color: white;
    height: 28px;
    cursor: pointer;
}

.size-list .filter {
    font-weight: 200;
}

.filter .hint {
    font-weight: 100;
}

.filter.pressed {
    color:white;
    background-color:black;
}

.filter.disabled {
    color: #777;
    border-color: #777;
}

.click {
    cursor: pointer;
}
